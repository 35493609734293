export const showHideVariants = {
  hide: { opacity: 0, transition: { duration: 0.6, type: 'spring' } },
  show: { opacity: 1, transition: { duration: 0.6, type: 'spring' } },
}

export const showHideInstantVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1 },
}

export const easing = [0.6, -0.05, 0.01, 0.99]
export const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
}
