import { Text, View } from '@cnd/elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextIconRow = ({ icon, text, children }) => {
  return (
    <View row spaceBetween style={{ padding: 15 }}>
      <View row center>
        <FontAwesomeIcon icon={icon} color={'grey'} />
        <Text style={{ marginInline: 10 }}>{text}</Text>
      </View>

      {children}
    </View>
  )
}

export default TextIconRow
