import { AnimatePresence, Button, Dot, LoadingAnimation, Text, View } from '@cnd/elements'
import { removeWhiteSpace, uppercase } from '@cnd/redash'
import { memo, useEffect, useRef } from 'react'
import useLocations from '@store/useLocations'
import { postcodeValidator } from 'postcode-validator'
import { useFunDispatch } from '@store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faClose, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import InputActionButton from './InputActionButton'
import useUpdateOrderLocation from '../useUpdateOrderLocation'
import ErrorBubble from '../ErrorBubble'
import { useTranslation } from 'react-i18next'
import { searchForClosestLocationToUser } from '@store/searchForClosestLocationToUser'
import useOrder from '@store/useOrder'
import { format } from 'date-fns'

import colors from '@constants/colors'
import ColoredButton from '@components/ColoredButton'

const removeSpecialCharactersAllowSpaces = (string) => string.replace(/[^a-zA-Z0-9 ]/g, '')

const ChoosePostCodeButton = ({ ...rest }) => {
  const { t } = useTranslation()
  const inputref = useRef(null)
  const dispatch = useFunDispatch()

  const { toggleChoosing } = useUpdateOrderLocation()

  const searchPostcode = useLocations('searchPostcode')
  const error = useLocations('postcodeValidError')
  const choosingLocation = useLocations('choosingLocation')
  const searching = useLocations('pending')

  const deliveryLocation = useOrder('deliveryLocation')
  const collectionLocation = useOrder('collectionLocation')
  const expectedDeliveryTimeFrom = useOrder('expectedDeliveryTimeFrom')
  const whenAndWhereSet = useOrder('whenAndWhereSet')

  const isPreOrder = useOrder('isPreOrder')
  const setSearchPostcode = dispatch('SET_LOCATION_SEARCH_POSTCODE')

  const clearPostcode = () => {
    dispatch('SET_LOCATION_SEARCH_POSTCODE')({ searchPostcode: '' })
    inputref.current.focus()
  }

  const search = async () => {
    const postcodeIsValid = postcodeValidator(removeWhiteSpace(searchPostcode || ''), 'UK')
    if (postcodeIsValid)
      dispatch('SET_LOCATION_CLOSEST_TO_USER', searchForClosestLocationToUser(searchPostcode))
    if (!postcodeIsValid) dispatch('SET_LOCATION_POSTCODE_VALID_ERROR', 'Please enter a valid postcode')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') search()
  }

  useEffect(() => {
    if (choosingLocation && inputref.current) {
      inputref.current.focus()
    }
  }, [choosingLocation])

  const DeliveryOrCollectionText = !!deliveryLocation ? t('Delivery') : t('Collection')
  const TimeUntilDeliveryText = isPreOrder
    ? format(new Date(expectedDeliveryTimeFrom), 'EEE do @ HH:mm')
    : !!deliveryLocation
    ? t('now')
    : t(' 25 mins')

  const collectionLocationPostcode = collectionLocation?.postcode
    ? `${t('from')} ${collectionLocation?.name}, ${collectionLocation?.postcode}`
    : ``
  let deliveryLocationPostcode = deliveryLocation?.postcode

  if (deliveryLocationPostcode) {
    if (deliveryLocation?.name) {
      deliveryLocationPostcode = `${deliveryLocation?.name}, ${deliveryLocation?.postcode}`
    } else {
      deliveryLocationPostcode = `${t('to')} ${deliveryLocationPostcode}`
    }
  }
  const inputValue = choosingLocation
    ? searchPostcode
    : collectionLocationPostcode || deliveryLocationPostcode || t(`What’s your postcode?`)

  const clearDeliveryLocation = () => {
    dispatch('SET_USER')({
      order: {
        whenAndWhereSet: false,
        isCollection: null,
        isPreOrder: null,
        collectionLocation: null,
        deliveryLocation: null,
        prepLocation: null,
        expectedDeliveryTimeFrom: null,
        expectedDeliveryTimeTo: null,
      },
    })
  }

  const atPartnerLocation = deliveryLocation?.['atPartnerLocation']
  const partnerLocationName = deliveryLocation?.['partnerLocationName']

  return (
    <View {...rest}>
      {choosingLocation && (
        <AnimatePresence>
          <View
            key={'close-button-stop-choosing'}
            style={{ width: '100%', marginTop: 30, zIndex: 19 }}
            onClick={toggleChoosing}
          >
            <ColoredButton size={45}>
              <FontAwesomeIcon icon={faClose} fontSize={18} />
            </ColoredButton>
          </View>
        </AnimatePresence>
      )}
      <View
        motion
        animate={{
          top: choosingLocation ? 20 : 0,
          height: !choosingLocation ? 56 : choosingLocation && !!error ? 200 : 156,
        }}
        style={{ position: 'relative', width: '100%', marginBottom: choosingLocation ? 20 : 0 }}
      >
        <View
          onClick={
            !choosingLocation
              ? () => {
                  console.log('choosing_location here')
                  toggleChoosing()
                }
              : () => {}
          }
          style={{
            borderRadius: 30,
            height: choosingLocation ? 150 : 'auto',
            width: '100%',
            backgroundColor: 'white',
            paddingLeft: 26,
            paddingRight: 26,
            cursor: 'pointer',
            position: 'relative',
            justifyContent: 'center',
            border: `1px solid ${colors.nBlack()}`,
          }}
        >
          <View>
            <View
              row
              motion
              animate={
                choosingLocation
                  ? { fontSize: '22px', top: 0, fontWeight: 600 }
                  : { fontSize: '12px', top: 6 }
              }
              style={{
                position: 'relative',
                cursor: !choosingLocation ? 'pointer' : 'auto',
                alignItems: 'center',
              }}
            >
              {(!whenAndWhereSet || choosingLocation) && !atPartnerLocation && t('When and where')}
              {atPartnerLocation && choosingLocation && t(`Still at ${partnerLocationName}?`)}
              {whenAndWhereSet && !choosingLocation && (
                <>
                  {DeliveryOrCollectionText}
                  <Dot size={3} color={'#8A8A8A'} style={{ marginLeft: 7, marginRight: 7 }} />
                  {TimeUntilDeliveryText}
                </>
              )}
            </View>

            <View
              motion
              animate={{ height: choosingLocation ? 56 : 26, marginTop: choosingLocation ? 20 : 5 }}
              style={{ position: 'relative' }}
            >
              <style jsx>{`
                input#postcode-input::placeholder {
                  color: black;
                }
              `}</style>
              <View style={{ position: 'relative' }}>
                {atPartnerLocation && choosingLocation && (
                  <View spaceBetween row style={{ position: 'absolute', zIndex: 10, background: 'white' }}>
                    <Button open style={{ marginRight: 10 }} onClick={clearDeliveryLocation}>
                      No
                    </Button>
                    <Button style={{ marginLeft: 10 }} onClick={toggleChoosing}>
                      Yes
                    </Button>
                  </View>
                )}
                {inputValue === '' && (
                  <View style={{ position: 'absolute', top: 17, left: 33, zIndex: 0 }}>
                    <Text size={14} color={'#8A8A8A'} weight={500}>
                      {t('Your Postcode')}
                    </Text>
                  </View>
                )}
                {!choosingLocation && (
                  <Text size={13} weight={500}>
                    {inputValue}
                  </Text>
                )}

                {choosingLocation && (
                  <input
                    id="postcode-input"
                    spellCheck={false}
                    autoComplete={'off'}
                    onKeyDown={handleKeyDown}
                    ref={inputref}
                    value={inputValue}
                    onChange={(e) => {
                      const value = e.target.value
                      const postcode = uppercase(removeSpecialCharactersAllowSpaces(value))
                      if (postcode.length > 8) return
                      setSearchPostcode(postcode)
                    }}
                    style={
                      choosingLocation
                        ? {
                            zIndex: 1,
                            borderRadius: 10,
                            border: choosingLocation ? '1px solid #E0E0E0' : 'none',
                            paddingBlock: 16,
                            paddingLeft: 16 + 17,
                            fontSize: 16,
                            fontWeight: 500,
                            width: '100%',
                            background: 'transparent',
                          }
                        : {
                            zIndex: 1,
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: 15,
                            fontWeight: 700,
                            color: '000000',
                            width: '100%',
                            padding: 0,
                          }
                    }
                  />
                )}
              </View>
              <View center style={{ position: 'absolute', right: 0, height: 56, top: -3, zIndex: 2 }}>
                {choosingLocation && (
                  <>
                    {searching ? (
                      <View center style={{ paddingBlock: 12, paddingInline: 17 }}>
                        <LoadingAnimation style={{ height: 13, width: 3 }} />
                      </View>
                    ) : (
                      <>
                        {!postcodeValidator(removeWhiteSpace(searchPostcode), 'UK') ? (
                          <InputActionButton onClick={search} opacity={0.3}>
                            <FontAwesomeIcon icon={faArrowRight} fontSize={18} />
                          </InputActionButton>
                        ) : (
                          <InputActionButton onClick={search}>
                            <FontAwesomeIcon icon={faArrowRight} fontSize={18} color={'black'} />
                          </InputActionButton>
                        )}

                        {postcodeValidator(removeWhiteSpace(searchPostcode), 'UK') && (
                          <View
                            onClick={clearPostcode}
                            style={{ position: 'absolute', right: 54, padding: 13, marginRight: 7 }}
                          >
                            <FontAwesomeIcon icon={faDeleteLeft} fontSize={18} color="lightgrey" />
                          </View>
                        )}
                      </>
                    )}
                  </>
                )}
              </View>
            </View>

            <ErrorBubble error={error} show={choosingLocation && !!error} />
          </View>
        </View>
      </View>
    </View>
  )
}

export default memo(ChoosePostCodeButton)
