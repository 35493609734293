import { map } from 'ramda'
import { removeWhiteSpace, uppercase } from '@cnd/redash'
import { Button, Card, Loading, Spacer, Text, View } from '@cnd/elements'
import { useFunDispatch, useTypedSelector } from '@store'

import { useSafeAreaInsets } from '@components/SafeAreaView'
import PillToggle from '@components/PillToggle'

import { builShopGoogledUrl } from '@services/url'
import { showHideVariants } from '@services/animate'

import DeliveryUnavailable from './DeliveryUnavailable'
import ClosestLocationItem from './ClosestLocationItem'
import UpdateLocationDeliveryDateSection from './UpdateLocationDeliveryDateSection'
import { useEffect, useState } from 'react'
import ErrorBubble from './ErrorBubble'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { useBoolean } from 'usehooks-ts'
import OpeningHoursModal from '@components/OpeningHours/OpeningHoursModal'
import useLocations from '@store/useLocations'
import { calculateCollectionTimeRange, calculateDeliveryTimeRange } from '@cnd/common/functions/delivery'

const OrderTimeAndDestinationPicker = ({ top = 50 }) => {
  const { value: showOpeningHours, toggle: toggleOpeningHours } = useBoolean(false)
  const [considering, setConsidering] = useState<'delivery' | 'collection' | ''>('')
  const { t } = useTranslation()
  const [closedNotification, setClosedNotification] = useState('')
  const locationSearchError = useLocations('error')

  const dispatch = useFunDispatch()

  const {
    closeBy: locationsCloseBy,
    closestToUser,
    searchPostcode,
    searchPreOrder,
    searchExpectedDeliveryTimeFrom,
    searchExpectedDeliveryTimeTo,
    choosingLocation,
    pending: searching,
  } = useTypedSelector((s) => s.locations)

  const searchOrderNow = !searchPreOrder

  useEffect(() => {
    if (!closestToUser?.isShopClosed) {
      setClosedNotification('')
    }
  }, [closestToUser?.isShopClosed])

  const deliveryAvailable = !!closestToUser?.deliveryAvailable
  const isShopClosed = !!closestToUser?.isShopClosed

  const stopChoosingLocation = () => {
    setConsidering('')
    dispatch('SET_CHOOSING_LOCATION', false)
  }

  const setConsideringDelivery = () => setConsidering('delivery')
  const setConsideringCollection = () => setConsidering('collection')

  const deliver = () => {
    setConsidering('')
    const newOrderDeliveryState = {
      whenAndWhereSet: true,
      isCollection: false,
      isPreOrder: searchPreOrder,
      deliveryLocation: { postcode: uppercase(searchPostcode) },
      collectionLocation: null,
      prepLocation: closestToUser,
      ...(!!searchPreOrder && {
        expectedDeliveryTimeFrom: searchExpectedDeliveryTimeFrom,
        expectedDeliveryTimeTo: searchExpectedDeliveryTimeTo,
      }),
      ...(!searchPreOrder && calculateDeliveryTimeRange([])),
    }
    console.log({ newOrderDeliveryState })
    dispatch('SET_ORDER_DELIVERY_STATE', newOrderDeliveryState)
    stopChoosingLocation()
  }

  const pickUp = (pickUpFromLocation) => () => {
    setConsidering('')
    const newOrderDeliveryState = {
      whenAndWhereSet: true,
      isCollection: true,
      isPreOrder: searchPreOrder,
      deliveryLocation: null,
      collectionLocation: pickUpFromLocation,
      prepLocation: pickUpFromLocation,
      ...(!!searchPreOrder && {
        expectedDeliveryTimeFrom: searchExpectedDeliveryTimeFrom,
        expectedDeliveryTimeTo: searchExpectedDeliveryTimeTo,
      }),
      ...(!searchPreOrder && calculateCollectionTimeRange()),
    }
    dispatch('SET_ORDER_DELIVERY_STATE', newOrderDeliveryState)
    stopChoosingLocation()
  }

  const setToPreOrder = () => {
    dispatch('SET_LOCATION_SEARCH_PREORDER', true)
  }

  const setToOrderNow = () => {
    dispatch('SET_LOCATION_SEARCH_PREORDER', false)
  }

  const preOrderSetAndTimePicked = !!searchExpectedDeliveryTimeFrom && !!searchExpectedDeliveryTimeTo

  const postcodeButtonTopOffset = 35
  const postcodeButtonHeight = 156
  const closeButtonOffsetX = 30
  const closeButtonHeight = 67
  const postCodeButtonMargin = 25
  const offestTop =
    closeButtonOffsetX +
    postcodeButtonTopOffset +
    postcodeButtonHeight +
    closeButtonHeight +
    postCodeButtonMargin

  if (!choosingLocation) return null

  return (
    <>
      <OpeningHoursModal toggle={toggleOpeningHours} show={showOpeningHours} />
      <View
        style={{
          position: 'absolute',
          top: offestTop,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'block',
          overflow: 'scroll',
          zIndex: 4,
        }}
      >
        <View
          key={'MenuPageHeader'}
          motion
          center
          variants={showHideVariants}
          initial={'hide'}
          animate={'show'}
          exit={'hide'}
        >
          {locationSearchError && (
            <>
              <Spacer height={top} />
              <ErrorBubble
                show={locationSearchError}
                error={`There was a problem looking up your location, please try again.`}
              />
            </>
          )}
          {!locationSearchError && !searching && closestToUser && !!choosingLocation && (
            <>
              <Spacer height={top} />
              <ErrorBubble
                show={locationSearchError}
                error={`There was a problem looking up your location, please try again.`}
              />
              <View center row>
                <PillToggle
                  left={{
                    label: t('Now'),
                    active: searchOrderNow,
                    disabled: isShopClosed,
                    set: isShopClosed
                      ? () =>
                          setClosedNotification(
                            t(
                              `Ordering for now is only possible when we're open. Tap the clock, for opening hours.`
                            )
                          )
                      : setToOrderNow,
                  }}
                  right={{ label: t('Schedule'), active: searchPreOrder, set: setToPreOrder }}
                />
                <View
                  motion
                  onClick={toggleOpeningHours}
                  whileTap={{ scale: 0.85 }}
                  center
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.07)',
                    borderRadius: 30,
                    marginLeft: 10,
                    position: 'relative',
                    width: 56,
                    height: 56,
                  }}
                >
                  <View style={{ position: 'absolute', zIndex: 1, top: 8, left: 8 }}>
                    <FontAwesomeIcon icon={faClock} fontSize={40} color={'white'} />
                  </View>
                  <View
                    style={{
                      backgroundColor: 'black',
                      borderRadius: 25,
                      overflow: 'hidden',
                      position: 'absolute',
                      top: 10,
                      left: 10,
                      height: 32,
                      width: 32,
                      zIndex: 0,
                    }}
                  />
                </View>
              </View>

              <Spacer height={15} />
              <ErrorBubble
                textStyle={{ maxWidth: 250 }}
                error={closedNotification}
                show={!!closedNotification}
                dismiss={() => setClosedNotification('')}
              />

              <Spacer height={20} />
              {searching && (
                <View center style={{ position: 'relative', marginTop: 50 }}>
                  <Loading />
                </View>
              )}
              {searchPreOrder && !searching && (
                <>
                  <UpdateLocationDeliveryDateSection
                    style={{ borderRadius: 15, width: '100%', maxWidth: 310 }}
                  />
                  <Spacer height={25} />
                </>
              )}
              {!searching && (
                <View
                  style={{
                    opacity: searchOrderNow ? 1 : preOrderSetAndTimePicked ? 1 : 0.15,
                    pointerEvents: searchOrderNow ? 'all' : preOrderSetAndTimePicked ? 'all' : 'none',
                    maxWidth: 320,
                    width: '100%',
                  }}
                >
                  <Card style={{ maxWidth: 320, width: '100%', padding: 0 }}>
                    <View row spaceBetween style={{ padding: 15 }}>
                      <Button borderWidth={2} open onClick={setConsideringCollection}>
                        {t('Pick up')}
                      </Button>
                      {deliveryAvailable ? (
                        <Button onClick={setConsideringDelivery}>
                          <Text weight={500} size={16} color="white">
                            {t('Deliver')}
                          </Text>
                        </Button>
                      ) : (
                        <View style={{ padding: 10 }}>
                          <DeliveryUnavailable />
                        </View>
                      )}
                    </View>

                    {considering === 'delivery' && (
                      <View
                        style={{
                          padding: 14,
                          backgroundColor: 'rgba(0,0,0,0.04)',
                          borderRadius: 10,
                        }}
                        row
                        center
                        spaceBetween
                      >
                        <View style={{ maxWidth: 150 }}>
                          {!searchPreOrder && (
                            <Text size={14}>{t('Delivery usually takes 25-35 minutes')}</Text>
                          )}
                          {searchPreOrder && (
                            <Text size={14}>{t('We will deliver to you for the time you have chosen')}</Text>
                          )}
                        </View>

                        <Button small onClick={deliver} style={{ marginTop: 10 }}>
                          {t('Confirm')}
                        </Button>
                      </View>
                    )}

                    {considering === 'collection' && (
                      <>
                        <Spacer height={20} />
                        {map((location) => {
                          const locationWithUrl = {
                            ...location,
                            url: builShopGoogledUrl(removeWhiteSpace(searchPostcode), location.url),
                          }
                          return (
                            <ClosestLocationItem
                              pickUp={pickUp(locationWithUrl)}
                              key={location.postcode}
                              location={locationWithUrl}
                            />
                          )
                        }, locationsCloseBy)}
                      </>
                    )}
                  </Card>

                  <Spacer height={25} />
                </View>
              )}
            </>
          )}
        </View>
      </View>
    </>
  )
}

export default OrderTimeAndDestinationPicker
