import { View } from '@cnd/elements'

const InputActionButton = ({ children, onClick, opacity = 1, border = true }) => (
  <View
    center
    onClick={onClick}
    style={{
      borderRadius: 30,
      width: 45,
      height: 45,
      marginRight: 8,
      opacity,
    }}
  >
    {children}
  </View>
)

export default InputActionButton
