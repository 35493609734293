import { View } from '@cnd/elements'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { showHideVariants } from '@services/animate'
import { useState } from 'react'

const BackdropDismisser = ({
  forwardKey = 'postcode_modal_backdrop',
  setOpen,
  color = '#f7f7f7',
  zIndex = 10,
}) => {
  const [tapCount, setTapCount] = useState(0)
  const insets = useSafeAreaInsets()

  const dismiss = () => {
    if (tapCount > 3) setOpen(false)
    setTapCount(tapCount + 1)
  }

  return (
    <View
      motion
      onClick={dismiss}
      key={forwardKey}
      variants={showHideVariants}
      animate={open ? 'show' : 'hide'}
      exit={'hide'}
      style={{
        height: '100vh',
        backgroundColor: color,
        paddingTop: insets.top,
        paddingBottom: insets.bottom,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex,
        pointerEvents: 'none',
      }}
    />
  )
}

export default BackdropDismisser
