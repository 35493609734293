import { Spacer, Text, View } from '@cnd/elements'
import useBusinessHours from '@store/useBusinessHours'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'

const OpeningHoursModal = ({ toggle, show }) => {
  const { openingHours } = useBusinessHours()
  const { t } = useTranslation()

  if (!show) return null
  return (
    <View center onClick={toggle} style={{ position: 'absolute', top: 50, left: 0, right: 0, zIndex: 20 }}>
      <View
        motion
        key={'opening-Hours-modal'}
        initial={{ width: 0, height: 0 }}
        animate={{ width: 320, height: 550 }}
        exit={{ width: 0, height: 0 }}
        transition={{ delay: 0, type: 'spring', stiffness: 260, damping: 20 }}
        style={{
          background: 'rgb(227, 51, 38)',
          borderRadius: 20,
          boxShadow: 'rgba(0, 0, 0, 0.5) 0px 8px 132px 2px',
          paddingTop: 30,
          textAlign: 'center',
        }}
      >
        <View
          motion
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.3, type: 'spring', stiffness: 260, damping: 20 }}
        >
          <Text bold size={24} style={{ color: 'white', textTransform: 'uppercase' }}>
            {t('Opening hours')}
          </Text>

          <Text size={14} style={{ color: 'white', padding: 20 }}>
            We're developing products and training staff so opening hours change each week. We're working to
            to make them more consistent.
          </Text>

          {map(openingHours, ({ id: day, openclose: time }) => (
            <View center key={day + time} style={{ margin: 5 }}>
              <View spaceBetween row style={{ width: '90%', borderBottom: '1px solid white' }}>
                <Text size={16} weight={500} style={{ color: 'white', paddingBlock: 5 }}>
                  {t(day)}
                </Text>
                <Text size={16} weight={500} style={{ color: 'black', paddingBlock: 5 }}>
                  {time === 'closed' && t(`closed`)}
                  {time !== 'closed' && (
                    <>
                      {time.split('-')[0]}
                      {' - '}
                      {time.split('-')[1]}
                    </>
                  )}
                </Text>
              </View>
            </View>
          ))}
          <Spacer height={25} />
          <Text bold size={12} style={{ textTransform: 'uppercase' }}>
            {t('close')}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default OpeningHoursModal
