import React from 'react'

import { Spacer, Text, View } from '@cnd/elements'
import { useTranslation } from 'react-i18next'

const DeliveryUnavailable = () => {
  const { t } = useTranslation()
  return (
    <View center>
      <Text size={18} weight={600} color="black" style={{ lineHeight: '20px' }}>
        {t('Delivery is unavailable')}
      </Text>
      <Spacer height={5} />
      <Text center size={14} weight={300} color="black" style={{ maxWidth: 290 }}>
        {t('Sadly you are too far away from us. We’re working hard to make it your way soon.')}
      </Text>
    </View>
  )
}

export default DeliveryUnavailable
