import { AnimatePresence, Spacer, Text, View } from '@cnd/elements'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showHideInstantVariants } from '@services/animate'

const ErrorBubble = ({ error, show, color = 'rgb(156 0 0)', textStyle = {}, dismiss = () => {} }) => (
  <AnimatePresence>
    {show && (
      <View
        onClick={dismiss}
        motion
        key={'animated_error_postcode'}
        variants={showHideInstantVariants}
        initial="hide"
        animate="show"
        exit="hide"
      >
        <Spacer height={15} />
        <View
          center
          style={{ borderRadius: 20, padding: 10, paddingInline: 20, background: 'rgba(0,0,0,0.05)' }}
        >
          <View center row>
            <FontAwesomeIcon icon={faWarning} fontSize={14} color={color} style={{ marginRight: 10 }} />
            <Text weight={400} color={color} center style={textStyle}>
              {error}
            </Text>
          </View>
        </View>
      </View>
    )}
  </AnimatePresence>
)

export default ErrorBubble
