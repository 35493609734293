import { setSidebarBottom } from '@store/actions'
import { View, Text, sectionStyle, Button } from '@cnd/elements'
import { useTypedDispatch, useTypedSelector } from '@store'
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { hasDeliveryDateBeenSelected, hasDeliveryTimeBeenSelected } from '@services/order'
import TextIconRow from '@components/CheckoutSteps/PlaceOrderForm/TextIconRow'
import { useTranslation } from 'react-i18next'

const UpdateLocationDeliveryDateSection = ({ style = {} }) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const expectedDeliveryTimeFrom = useTypedSelector((s) => s.locations.searchExpectedDeliveryTimeFrom)
  const expectedDeliveryTimeTo = useTypedSelector((s) => s.locations.searchExpectedDeliveryTimeTo)

  const deliveryDateSelected = hasDeliveryDateBeenSelected({ expectedDeliveryTimeFrom })
  const deliveryTimeSelected = hasDeliveryTimeBeenSelected({ expectedDeliveryTimeFrom })

  const editDate = () => {
    dispatch(
      setSidebarBottom({
        content: 'EditNewUserDeliveryDate',
        isOpen: true,
        height: '90vh',
        data: { isFor: 'locations' },
      })
    )
  }
  const editTime = () => {
    dispatch(
      setSidebarBottom({
        content: 'EditNewUserDeliveryTime',
        isOpen: true,
        height: '90vh',
        data: { isFor: 'locations' },
      })
    )
  }

  return (
    <View style={{ ...sectionStyle, ...style }}>
      <TextIconRow
        icon={faCalendar}
        text={
          <>
            {deliveryDateSelected
              ? `${format(new Date(expectedDeliveryTimeFrom), 'EEEE')} the ${format(
                  new Date(expectedDeliveryTimeFrom),
                  'do'
                )}`
              : t('Select date')}
          </>
        }
        children={
          <Button small open={deliveryDateSelected} onClick={editDate}>
            {!deliveryDateSelected ? t('select') : t('change')}
          </Button>
        }
      />
      <TextIconRow
        icon={faClock}
        text={
          <>
            {deliveryTimeSelected ? (
              <View row>
                <View>
                  <Text style={{ paddingInline: 5 }}>
                    {`${format(new Date(expectedDeliveryTimeFrom), 'HH:mm')} - ${format(
                      new Date(expectedDeliveryTimeTo),
                      'HH:mm bbbb'
                    )}`}
                  </Text>
                </View>
              </View>
            ) : (
              t('Select time')
            )}
          </>
        }
        children={
          <Button small open={deliveryTimeSelected} disabled={!deliveryDateSelected} onClick={editTime}>
            {!deliveryTimeSelected ? t('add') : t('change')}
          </Button>
        }
      />
    </View>
  )
}
export default UpdateLocationDeliveryDateSection
