import api from '@services/api'

import { mergeDeepRight } from 'ramda'
import { removeWhiteSpace } from '@cnd/redash'

export const searchForClosestLocationToUser = (postcode) =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(`Searching for closest location to ${postcode}`)
      const { data: userLatLng } = await api.post('/GetLatLng', { postcode })

      console.log(`Found for userLatLng to ${userLatLng}`)

      const { data: dkDirections } = await api.post('/directions', {
        toAddress: removeWhiteSpace(postcode),
      })

      console.log(`Found for dkDirections to ${dkDirections}`)

      const { data: locations } = await api.get('/locations')

      console.log(`Found for locations to ${locations}`)

      const locationsWithDistanceFromUserAdded = mergeDeepRight(locations, {
        SE16PF: {
          minsFromLocation: Number(dkDirections.totalDurationInMinutes || 0),
          deliveryAvailable: dkDirections.canDeliver,
        },
      })

      console.log(`Found for locationsWithDistanceFromUserAdded to ${locationsWithDistanceFromUserAdded}`)

      resolve({
        locations: locationsWithDistanceFromUserAdded,
        searchPostcode: postcode,
        searchPostcodeLatLng: userLatLng,
      })
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.error?.message) return reject(e?.response?.data?.error?.message)
      reject('Search failed, please try again.')
    }
  })
