import React, { useEffect, useRef, useState } from 'react'
import { Button, LoadingAnimation, Spacer, Text, View } from '@cnd/elements'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api'
import { useTypedSelector } from '@store'
import { absoluteFillBottom, absoluteFillTop } from '@services/StyleSheet'

const middleOfTwoLatLngs = (latLng1, latLng2) => ({
  lat: (latLng1.lat + latLng2.lat) / 2,
  lng: (latLng1.lng + latLng2.lng) / 2,
})

const CollectionMap = ({ mapStyle = {} }) => {
  const latLng = useTypedSelector((s) => s.locations.closestToUser.latLng)
  const searchPostcodeLatLng = useTypedSelector((s) => s.locations.searchPostcodeLatLng)

  console.log('rendering CollectionMap')
  const [directions, setDirections] = useState(null)
  const directionsService = useRef(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY,
  })

  const onLoadMap = () => {
    directionsService.current = new google.maps.DirectionsService()
  }

  useEffect(() => {
    console.log('directionsService.current.route searchPostcodeLatLng', searchPostcodeLatLng)
    if (directionsService.current && directionsService.current.route && !directions)
      directionsService.current.route(
        {
          origin: searchPostcodeLatLng,
          destination: latLng,
          travelMode: google.maps.TravelMode.WALKING,
        },
        (result, status) => {
          console.log('directionsService.current.route search result', result)
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result)
          }
        }
      )
  }, [latLng, searchPostcodeLatLng])

  const showMap = searchPostcodeLatLng && latLng

  if (!isLoaded)
    return (
      <View center style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0.04)' }}>
        <LoadingAnimation style={{ height: 15, width: 3 }} />
      </View>
    )

  console.log({ directions })

  if (isLoaded && showMap)
    return (
      <>
        {!directions && (
          <View center style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0.04)' }}>
            <LoadingAnimation style={{ height: 15, width: 3 }} />
          </View>
        )}
        <GoogleMap
          mapContainerStyle={{
            height: 300,
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 60,
            bottom: 40,
            opacity: directions ? 1 : 0,
            ...mapStyle,
          }}
          center={middleOfTwoLatLngs(latLng, searchPostcodeLatLng)}
          zoom={14}
          onLoad={onLoadMap}
          options={{
            disableDefaultUI: true,
            zoomControl: false,
            scrollwheel: false,
          }}
        >
          <DirectionsRenderer directions={directions} />
        </GoogleMap>
      </>
    )
  return null
}

const ClosestLocationItem = ({ location, pickUp }) => {
  console.log('rendering ClosestLocationItem')

  const { t } = useTranslation()
  const { name, addressLineOne, postcode, minsFromLocation, url } = location

  return (
    <View row style={{ maxWidth: 320, width: '100%', position: 'relative', height: 400, overflow: 'hidden' }}>
      <CollectionMap mapStyle={{ marginTop: 30 }} />
      <View
        grow
        style={{
          position: 'absolute',
          zIndex: 10,
          ...absoluteFillTop,
          backgroundColor: 'white',
          justifyContent: 'flex-end',
          padding: 15,
          paddingTop: 5,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        }}
      >
        <Text size={17} weight={600} color="black" style={{ lineHeight: '20px' }}>
          {t('You will be collecting from')}
        </Text>

        <Spacer height={5} />
        <Text weight={500} size={16}>
          {name}
        </Text>
        <Text weight={400} size={14}>
          {addressLineOne}
        </Text>
        <Text weight={400} size={14}>
          {postcode}
        </Text>

        <Spacer height={5} />
        <View row onClick={() => window.open(url, '_blank')} style={{ cursor: 'pointer' }}>
          <View center row>
            <Text weight={500} size={14} style={{ marginRight: 6 }}>
              {t('Get directions')}
            </Text>
            <Image
              src={'/forward-arrow.webp'}
              height={10}
              width={11}
              alt="Arrow forward"
              style={{ position: 'relative', top: 1 }}
            />
          </View>
        </View>
        <Spacer height={10} />
      </View>
      <View
        center
        row
        style={{
          position: 'absolute',
          zIndex: 10,
          ...absoluteFillBottom,
          backgroundColor: 'white',
          justifyContent: 'flex-end',
          padding: 15,
          borderRadius: 15,
        }}
      >
        <Text bold size={14} style={{ padding: 10 }} color={minsFromLocation > 25 ? 'red' : 'black'}>
          {minsFromLocation} {t('min walk')}
        </Text>
        <Button small open onClick={pickUp} borderWidth={2}>
          <Text weight={600} size={16}>
            {t('Confirm')}
          </Text>
        </Button>
      </View>
    </View>
  )
}
export default ClosestLocationItem
