import { View, Text } from '@cnd/elements'
import Pill from '@components/Pill'
import colors from '@constants/colors'

const showHidePillVariants = {
  show: { backgroundColor: ['rgba(0,0,0,0)', 'rgba(255,255,255,1)'] },
  hide: { backgroundColor: ['rgba(255,255,255,1)', 'rgba(0,0,0,0)'] },
}

type PillToggleProps = {
  left: {
    disabled?: boolean
    label: string
    active: boolean
    set: () => void
  }
  right: {
    disabled?: boolean
    label: string
    active: boolean
    set: () => void
  }
}

const PillToggle = ({ left, right }: PillToggleProps) => {
  return (
    <View
      row
      spaceBetween
      style={{ width: 200, background: 'rgba(0,0,0,0.07)', borderRadius: 30, padding: 8 }}
    >
      <Pill
        motion
        variants={showHidePillVariants}
        animate={left.active ? 'show' : 'hide'}
        onClick={left.set}
        style={{
          height: 10,
          paddingInline: 20,
          paddingBlock: 20,
          border: left.active ? `1px solid ${colors.nBlack()}` : 'none',
        }}
      >
        <Text
          color="black"
          weight={left.active ? 600 : 400}
          center
          style={{ opacity: left.disabled ? 0.4 : 1 }}
        >
          {left.label}
        </Text>
      </Pill>

      <Pill
        motion
        variants={showHidePillVariants}
        animate={right.active ? 'show' : 'hide'}
        onClick={right.set}
        style={{
          height: 10,
          paddingInline: 20,
          paddingBlock: 20,
          border: right.active ? `1px solid ${colors.nBlack()}` : 'none',
        }}
      >
        <Text
          color="black"
          weight={right.active ? 600 : 400}
          center
          style={{ opacity: right.disabled ? 0.4 : 1 }}
        >
          {right.label}
        </Text>
      </Pill>
    </View>
  )
}

export default PillToggle
