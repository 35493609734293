import Image, { ImageProps } from 'next/image'
import { useState } from 'react'

const ImageShimmer = ({ alt, src, style, ...rest }: ImageProps) => {
  const [visible, setVisible] = useState(false)

  return (
    <Image
      alt={alt}
      src={src}
      onLoadingComplete={() => {
        setVisible(true)
      }}
      style={{
        ...style,
        transition: 'all 600ms',
        opacity: visible ? 1 : 0,
      }}
      {...rest}
    />
  )
}
export default ImageShimmer
