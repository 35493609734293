import { View } from '@cnd/elements'

const Pill = ({ color = 'black', children = null, style = {}, shadow = false, onClick = null, ...rest }) => (
  <View
    {...(onClick && { onClick })}
    center
    row
    style={{
      borderRadius: 100,
      boxShadow: shadow ? `-1px 2px 3px rgba(0,0,0,0.2)` : `none`,
      backgroundColor: color,
      cursor: !!onClick ? 'pointer' : 'default',
      ...style,
    }}
    {...rest}
  >
    {children}
  </View>
)

export default Pill
