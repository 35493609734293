import { getHours } from 'date-fns'

export const hasValidPhoneNumber = (order) => {
  return order && !!order.customerPhoneNumber
}

export const hasDeliveryTimeBeenSelected = (order) => {
  const { expectedDeliveryTimeFrom } = order || {}
  if (!expectedDeliveryTimeFrom) return false
  const hours = getHours(new Date(expectedDeliveryTimeFrom))
  if (hours === 0) return false
  return true
}

export const hasDeliveryDateBeenSelected = (order) => {
  const { expectedDeliveryTimeFrom } = order || {}
  return !!expectedDeliveryTimeFrom ? true : false
}
