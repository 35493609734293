import { useTypedDispatch } from '@store'
import { throttle } from 'lodash'

const useUpdateOrderLocation = () => {
  const dispatch = useTypedDispatch()

  const stopChoosing = (e = null) => {
    console.log('stop_choosing_location')
    if (e?.stopPropagation) e.stopPropagation()
    dispatch({ type: 'QUIT_CHOOSING_LOCATION' })
  }
  const startChoosing = (e = null) => {
    if (e?.stopPropagation) e.stopPropagation()
    console.log('choosing_location')
    dispatch({ type: 'SET_CHOOSING_LOCATION', payload: true })
  }

  const toggleChoosing = throttle((e = null) => {
    if (e?.stopPropagation) e.stopPropagation()
    dispatch({ type: 'TOGGLE_CHOOSING_LOCATION' })
  }, 1000)

  return { stopChoosing, startChoosing, toggleChoosing }
}

export default useUpdateOrderLocation
