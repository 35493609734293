import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { View } from '@cnd/elements'

const PlusMinusCounter = ({ plus, minus, count }) => {
  return (
    <View
      row
      style={{
        backgroundColor: 'rgb(239 232 232)',
        borderRadius: 20,
        height: 40,
        width: count > 0 ? 76 + 24 : 76,
        transition: 'width 0.3s ease',
        justifyContent: count === 0 ? 'center' : 'space-between',
      }}
    >
      {count > 0 && (
        <View
          motion
          center
          style={{ width: 38 }}
          onClick={minus}
          animate={{ opacity: 1 }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FontAwesomeIcon color={'black'} style={{ fontSize: 14 }} icon={faMinus} />
        </View>
      )}
      {count > 0 && (
        <View
          motion
          animate={{ width: 24 }}
          initial={{ width: 0 }}
          exit={{ width: 0 }}
          center
          style={{
            fontSize: 18,
            paddingTop: 1,
            fontWeight: 'bold',
          }}
        >
          {count}
        </View>
      )}
      <View center style={{ width: 38 }} onClick={plus}>
        <FontAwesomeIcon color={'black'} style={{ fontSize: 14, opacity: 0.7 }} icon={faPlus} />
      </View>
    </View>
  )
}
export default PlusMinusCounter
